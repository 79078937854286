import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { iconCheckmark, IconSvg, VariantUsps } from '@graphcommerce/next-ui'
import { Box, Typography } from '@mui/material'

import { RowLinksFragment } from '../RowLinks.gql'

export function Usps(props: RowLinksFragment) {
  const { title, pageLinks } = props

  return (
    <VariantUsps
      title={title}
      maxWidth={false}
      sx={(theme) => ({
        mb: theme.spacings.lg,
        '& .Scroller-root > *': {
          [theme.breakpoints.only('xs')]: {
            '&:nth-last-of-type(-n+3)': {
              display: 'none',
            },
          },
          [theme.breakpoints.only('sm')]: {
            '&:nth-last-of-type(-n+2)': {
              display: 'none',
            },
          },
          [theme.breakpoints.only('md')]: {
            '&:nth-last-of-type(-n+1)': {
              display: 'none',
            },
          },
        },
      })}
    >
      {pageLinks.map((pageLink) => (
        <Box
          key={pageLink.id}
          sx={{
            display: 'inline-flex',
            flexWrap: 'nowrap',
            gap: 1,
            alignItems: 'center',
          }}
        >
          {pageLink.asset ? (
            <Box
              sx={{
                height: 32,
                width: 32,
                aspectRatio: '1/1',
                '& img': {
                  display: 'block',
                },
              }}
            >
              <Image
                src={pageLink.asset.url}
                alt={pageLink.asset.alt ?? undefined}
                layout='fill'
                unoptimized
              />
            </Box>
          ) : (
            <IconSvg src={iconCheckmark} sx={{ color: 'primary.main' }} />
          )}
          <Box>
            <Typography variant='h6' component='strong'>
              {pageLink.title}
            </Typography>{' '}
            {pageLink?.description && (
              <RichText
                {...pageLink.description}
                sxRenderer={{
                  paragraph: {
                    display: 'inline',
                  },
                  link: {
                    color: 'text.primary',
                    textDecoration: 'underline',
                  },
                }}
              />
            )}
          </Box>
        </Box>
      ))}
    </VariantUsps>
  )
}
