import { Image } from '@graphcommerce/image'
import { Row } from '@graphcommerce/next-ui'
import { Box, Typography, Button } from '@mui/material'
import { RowCtaBlockListFragment } from './RowCtaBlockList.gql'

export function RowCtaBlockList(props: RowCtaBlockListFragment) {
  const { ctaBlock } = props

  return (
    <Row
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacings.sm,
        mb: theme.spacings.xxl,
      })}
    >
      {ctaBlock.map((item) => {
        const { id, title, asset, button } = item

        return (
          <Box
            key={id}
            sx={(theme) => ({
              position: 'relative',
              backgroundColor:
                button?.buttonColor === 'primary' ? theme.palette.background.grey : '',
              border:
                button?.buttonColor === 'primary'
                  ? ''
                  : `2px solid ${theme.palette.text.secondary}`,
              padding: `${theme.spacings.xs} ${theme.spacings.md}`,
              display: 'grid',
              alignItems: 'center',
              gap: `${theme.spacings.lg} ${theme.spacings.md}`,
              gridTemplateColumns: '2fr 1fr',
              gridTemplateAreas: `
                "title title"
                "button image"
              `,

              [theme.breakpoints.up('md')]: {
                gridTemplateRows: '1fr',
                gridTemplateColumns: '2fr 1fr 1fr',
                gridTemplateAreas: `
                  "title image button"
                `,
              },
              [theme.breakpoints.up('lg')]: {
                gridTemplateColumns: '3fr 1fr 1fr',
              },
            })}
          >
            <Typography
              variant='h3'
              component='p'
              sx={(theme) => ({
                gridArea: 'title',
                [theme.breakpoints.down('md')]: {
                  typography: 'h5',
                },
              })}
            >
              {title}
            </Typography>
            <Image
              src={asset?.url ?? ''}
              alt={asset?.alt ?? undefined}
              width={200}
              height={200}
              sizes='200px'
              layout='intrinsic'
              sx={(theme) => ({
                position: 'absolute',
                bottom: 0,
                right: 0,
                objectFit: 'cover',
                objectPosition: 'top right',
                maxHeight: '75px',
                [theme.breakpoints.up('md')]: {
                  maxHeight: '100%',
                  right: 'auto',
                },
              })}
              pictureProps={{ sx: { gridArea: 'image', display: 'flex', justifyContent: 'end' } }}
            />
            <Button
              href={button?.buttonUrl ?? ''}
              variant='pill'
              color={button?.buttonColor ?? 'primary'}
              size='large'
              sx={(theme) => ({
                minWidth: 'auto',
                width: 'fit-content',
                gridArea: 'button',
                [theme.breakpoints.up('md')]: { justifySelf: 'end' },
              })}
            >
              {button?.buttonText}
            </Button>
          </Box>
        )
      })}
    </Row>
  )
}
