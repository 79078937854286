import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { responsiveVal, RowLinks } from '@graphcommerce/next-ui'
import { ButtonBase, Typography } from '@mui/material'
import React from 'react'
import { RowLinksFragment } from '../RowLinks.gql'

export function Tiles(props: RowLinksFragment) {
  const { title, pageLinks } = props

  return (
    <RowLinks
      maxWidth={false}
      title={title}
      sx={(theme) => ({
        mb: theme.spacings.xxl,
        '& .RowLinks-title': {
          ...theme.typography.h2,
          marginBottom: theme.spacings.md,
        },
        '& .RowLinks-copy': { my: 0 },
        '& .Scroller-root': {
          gap: theme.spacings.xl,
          alignItems: 'start',
        },
      })}
    >
      {pageLinks.map((pageLink) => (
        <ButtonBase
          href={pageLink.url}
          key={pageLink.id}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            height: '100%',
            background: theme.palette.background.paper,
            width: responsiveVal(230, 430),
            padding: responsiveVal(15, 65),
            cursor: pageLink.url === '#' ? 'default' : 'pointer',
            '& img': { display: 'block' },
          })}
          disableRipple={pageLink.url === '#'}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            pageLink.url === '#' && e.preventDefault()
          }
        >
          {pageLink?.asset && (
            <Image
              src={pageLink.asset.url}
              alt={pageLink.asset.alt ?? undefined}
              sizes={responsiveVal(60, 100)}
              layout={pageLink.asset.mimeType === 'image/svg+xml' ? 'fill' : 'intrinsic'}
              unoptimized={pageLink.asset.mimeType === 'image/svg+xml'}
              width={pageLink.asset.mimeType === 'image/svg+xml' ? 112 : pageLink.asset.width ?? 0}
              height={
                pageLink.asset.mimeType === 'image/svg+xml' ? 112 : pageLink.asset.height ?? 0
              }
              sx={[
                (theme) => ({
                  mb: theme.spacings.sm,
                }),
                pageLink.asset.mimeType === 'image/svg+xml' && {
                  width: responsiveVal(60, 100),
                  height: responsiveVal(60, 100),
                },
                pageLink.asset.mimeType !== 'image/svg+xml' && {
                  width: responsiveVal(60, 100),
                  height: 'auto',
                },
              ]}
            />
          )}
          <Typography variant='h3' sx={(theme) => ({ mb: theme.spacings.sm })}>
            {pageLink.title}
          </Typography>
          {pageLink.description && <RichText {...pageLink.description} />}
        </ButtonBase>
      ))}
    </RowLinks>
  )
}
