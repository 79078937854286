import { Asset } from '@graphcommerce/graphcms-ui'
import { responsiveVal, VariantImageLabelSwiper } from '@graphcommerce/next-ui'
import { ButtonBase, SxProps, Theme, Typography } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'

export function ImageTextSwiper(props: RowLinksFragment & { sx?: SxProps<Theme> }) {
  const { title, pageLinks, sx } = props

  return (
    <VariantImageLabelSwiper
      maxWidth={false}
      title={title}
      showButtons='auto'
      sx={[
        (theme) => ({
          mb: theme.spacings.xl,
          display: 'flex',
          flexDirection: 'column',
          '& .RowLinks-title': {
            ...theme.typography.h2Overline,
            mb: theme.spacings.sm,
          },
          '& .Scroller-root': {
            gap: theme.spacings.lg,
          },
          '& .Scroller-root > .MuiButtonBase-root': {
            overflow: 'hidden',
            '& img, & video': {
              filter: 'none',
              willChange: 'unset',
              width: responsiveVal(260, 390),
              maxWidth: responsiveVal(260, 390),

              objectFit: 'cover',
              objectPosition: 'center',
              aspectRatio: '2 / 3',
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {pageLinks.map((pageLink) => (
        <ButtonBase
          href={pageLink.url}
          key={pageLink.id}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: theme.spacings.md,
            width: responsiveVal(260, 390),
            maxWidth: responsiveVal(260, 390),
            '& img': { display: 'block' },
          })}
        >
          {pageLink?.asset && (
            <Asset
              asset={pageLink.asset}
              sx={{
                width: responsiveVal(260, 390),
                maxWidth: responsiveVal(260, 390),
              }}
              sizes={responsiveVal(260, 390)}
              quality={80}
            />
          )}
          <Typography variant='h4' component='h3'>
            {pageLink.title}
          </Typography>
        </ButtonBase>
      ))}
    </VariantImageLabelSwiper>
  )
}
