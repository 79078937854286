import { AddProductsToCartForm, RelatedProductsFragment } from '@graphcommerce/magento-product'
import { RenderType, responsiveVal, SidebarSliderProps } from '@graphcommerce/next-ui'
import { Typography } from '@mui/material'
import { productListRenderer } from '../../../ProductListItems/productListRenderer'
import { RowProductFragment } from '../RowProduct.gql'
import { ProductSlider } from '../components/ProductSlider'

type RelatedProps = RowProductFragment & RelatedProductsFragment & Pick<SidebarSliderProps, 'sx'>

export function Related(props: RelatedProps) {
  const { title, related_products, sx } = props

  if (!related_products || related_products.length === 0) return null

  return (
    <AddProductsToCartForm>
      <ProductSlider
        sx={sx}
        sidebar={
          <Typography
            variant='subtitle1'
            component='h2'
            typography='h2Overline'
            sx={(theme) => ({
              marginBottom: theme.spacings.sm,
            })}
          >
            {title}
          </Typography>
        }
      >
        {related_products?.map((item) =>
          item ? (
            <RenderType
              key={item.uid ?? ''}
              renderer={productListRenderer}
              sizes={responsiveVal(200, 400)}
              titleComponent='h3'
              {...item}
            />
          ) : null,
        )}
      </ProductSlider>
    </AddProductsToCartForm>
  )
}
