import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { Button, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Container, Typography } from '@mui/material'

import { RowLinksFragment } from '../RowLinks.gql'

export function ActionTiles(props: RowLinksFragment) {
  const { callToActions, rowLinksCopy, title } = props

  return (
    <Container sx={(theme) => ({ marginBottom: theme.spacings.xxl })}>
      {title !== '-' && (
        <Typography
          variant='h2'
          component='h2'
          textAlign='center'
          sx={(theme) => ({ mb: theme.spacings.sm })}
        >
          {title}
        </Typography>
      )}
      {rowLinksCopy && <Box textAlign='center' sx={(theme) => ({ mb: theme.spacings.md })} />}
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacings.sm,
          flexWrap: 'wrap',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        })}
      >
        {callToActions.map((cta, index) => (
          <Box
            sx={(theme) => ({
              backgroundColor:
                index === 0 ? theme.palette.primary.main : theme.palette.background.paper,
              color: index === 0 ? theme.palette.primary.contrastText : 'inherit',
              padding: theme.spacings.md,
              maxWidth: 540,
              flex: '1 1 0',
              position: 'relative',
            })}
            key={cta.title}
          >
            {cta.asset && (
              <Image
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  width: responsiveVal(90, 120),
                  height: 'auto',
                }}
                sizes={responsiveVal(90, 120)}
                src={cta.asset.url}
                alt={cta.asset.alt ?? undefined}
                layout='fill'
                unoptimized={cta.asset.mimeType === 'image/svg+xml'}
              />
            )}
            {cta.title && <Typography variant='h3'>{cta.title}</Typography>}
            {cta.content && (
              <Box sx={(theme) => ({ mb: theme.spacings.sm })}>
                <RichText {...cta.content} />
              </Box>
            )}
            {cta.buttonText && cta.buttonUrl && (
              <Button
                variant='pill'
                color={index === 0 ? 'inherit' : 'primary'}
                href={cta.buttonUrl}
              >
                {cta.buttonText}
              </Button>
            )}
          </Box>
        ))}
      </Box>
    </Container>
  )
}
