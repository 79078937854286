import { Asset, RichText } from '@graphcommerce/graphcms-ui'
import { responsiveVal, VariantImageLabelSwiper } from '@graphcommerce/next-ui'
import { Box, ButtonBase, Typography } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'

export function ImageLabelSwiper(props: RowLinksFragment) {
  const { title, rowLinksCopy, pageLinks } = props

  return (
    <VariantImageLabelSwiper
      maxWidth={false}
      title={title}
      copy={rowLinksCopy && <RichText {...rowLinksCopy} />}
      sx={(theme) => ({
        '& .RowLinks-title': {
          ...theme.typography.h2Overline,
          marginBottom: theme.spacings.sm,
        },
        '& .RowLinks-copy': { my: 0 },
        '& .Scroller-root': {
          gap: responsiveVal(15, 80),
        },
      })}
    >
      {pageLinks.map((pageLink, index) => (
        <ButtonBase
          href={pageLink.url}
          key={pageLink.id}
          sx={() => ({
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            '& img': { display: 'block' },
            // @todo if these banners are often changed we need a toggle or something to control the font color in Hygraph. (I've tried using a class for this in HG but that resulted in some errors)
            color: index > 2 ? 'black' : 'white',
          })}
        >
          {pageLink?.asset && (
            <Asset
              asset={pageLink.asset}
              sx={{
                width: responsiveVal(220, 390),
                maxWidth: responsiveVal(220, 390),
              }}
              sizes={responsiveVal(260, 389)}
            />
          )}
          <Box
            sx={(theme) => ({ position: 'absolute', top: 0, left: 0, margin: theme.spacings.sm })}
          >
            <Box sx={{ maxWidth: responsiveVal(260, 390) }}>
              <Typography variant='h2' component='h3'>
                {pageLink.title}
              </Typography>
              <Box
                sx={(theme) => ({
                  ...theme.typography.h2,
                })}
              >
                {pageLink?.description && (
                  <RichText
                    {...pageLink.description}
                    sxRenderer={{
                      paragraph: (theme) => ({
                        ...theme.typography.h5,
                      }),
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </ButtonBase>
      ))}
    </VariantImageLabelSwiper>
  )
}
