import { Asset } from '@graphcommerce/graphcms-ui'
import { Row, responsiveVal } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { BannerContent } from '../Components/BannerContent'
import { RowContentBannerCtaFragment } from '../RowContentBannerCta.gql'

export function Inset(props: RowContentBannerCtaFragment) {
  const { bannerAsset, identity, ...rest } = props
  const mimeType = bannerAsset?.mimeType

  if (!mimeType) return null

  return (
    <Row
      maxWidth='md'
      sx={(theme) => ({
        mb: theme.spacings.xxl,
      })}
      id={identity}
    >
      {bannerAsset && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            marginBottom: theme.spacings.md,
          })}
        >
          <Asset
            asset={bannerAsset}
            quality={84}
            sizes={responsiveVal(350, 900)}
            layout='fill'
            pictureProps={{
              sx: {
                flex: 1,
                height: responsiveVal(123, 320),
              },
            }}
            sx={[
              {
                objectFit: 'cover',
                objectPosition: 'center 40%',
              },
              bannerAsset.mimeType === 'video/mp4' && {
                width: '100%',
                height: responsiveVal(123, 320),
              },
            ]}
          />
        </Box>
      )}

      <BannerContent identity={identity} {...rest} />
    </Row>
  )
}
