import { useCallback, useEffect } from 'react'
import { RowFormsAppFragment } from './RowFormsApp.gql'
import { FullWidth, TwoColumn } from './variant'

type VariantRenderer = Record<
  NonNullable<RowFormsAppFragment['formVariant']>,
  React.VFC<RowFormsAppFragment>
>

type RowFormsAppProps = RowFormsAppFragment & {
  renderer?: Partial<VariantRenderer>
  init?: any
}

const defaultRenderer: Partial<VariantRenderer> = {
  FullWidth,
  TwoColumn,
}

export function RowFormsApp(props: RowFormsAppProps) {
  const { renderer, formId, formVariant, ...rest } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as VariantRenderer
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line new-cap
  const init = useCallback(() => new formsapp(formId), [formId])

  useEffect(() => {
    if (globalThis.formsapp && !document.querySelector('iframe[src*="forms.app"]')) {
      // eslint-disable-next-line no-new, new-cap
      new globalThis.formsapp(formId)
    }
  })

  if (!formVariant) return null

  const RenderType =
    mergedRenderer?.[formVariant] ??
    (() => {
      if (process.env.NODE_ENV !== 'production') return <>renderer for {formVariant} not found</>
      return null
    })

  return <RenderType init={init} formId={formId} {...rest} />
}
