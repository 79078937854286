import { RichText } from '@graphcommerce/graphcms-ui'
import { ColumnTwo } from '@graphcommerce/next-ui'
import { Box, Button, Typography } from '@mui/material'
import { RowContentBannerCtaFragment } from '../RowContentBannerCta.gql'

export function BannerContent(props: Omit<RowContentBannerCtaFragment, 'bannerAsset'>) {
  const { title, copy, copyButton } = props

  return (
    <ColumnTwo
      sx={(theme) => ({
        px: 0,
        mb: 0,
        gridRowGap: theme.spacings.xs,
        [theme.breakpoints.up('sm')]: {
          px: 0,
        },
      })}
      maxWidth={false}
      colOneContent={<Typography variant='h2'>{title}</Typography>}
      colTwoContent={
        <>
          <Box sx={(theme) => ({ marginBottom: copyButton ? theme.spacings.sm : 0 })}>
            <RichText
              {...copy}
              sxRenderer={{
                'heading-three': (theme) => ({
                  '&:first-of-type': {
                    mt: theme.spacings.lg,
                  },
                  marginTop: theme.spacings.md,
                  marginBottom: theme.spacings.sm,
                }),
              }}
            />
          </Box>
          {copyButton && (
            <Button
              href={copyButton.buttonUrl ?? ''}
              size='large'
              variant='pill'
              color={copyButton.buttonColor ?? 'primary'}
              sx={(theme) => ({
                ...theme.typography.h5,
              })}
            >
              {copyButton.buttonText}
            </Button>
          )}
        </>
      }
    />
  )
}
