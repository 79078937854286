import { useQuery } from '@graphcommerce/graphql'
import { ContainerWithHeader, nonNullable } from '@graphcommerce/next-ui'
import { Link } from '@mui/material'
import { ProductListItems, ProductListItemsProps } from '../../../ProductListItems/ProductListItems'
import { productsByCategoryOrSkuDocument } from '../ProductsByCategoryOrSku.gql'
import { RowProductFragment } from '../RowProduct.gql'

type GridProps = RowProductFragment & ProductListItemsProps

export function Grid(props: GridProps) {
  const { title, pageLinks, productCopy, categoryId, skUs, ...productListItems } = props

  const { items } = productListItems
  const skus = skUs?.replaceAll(' ', '').split(',')

  // We've modified Grid.tsx so its able to fetch a bunch of products based on a set of SKUs or a category ID from Hygraphs' RowProduct
  // This works just fine, but will cause a client-side api call and thus fetch the content AFTER pageload (possibly causing some CLS)
  // So if possible, always pass the `items` attribute from getStaticProps whenever you're using <RowProduct/> (For example showing a top-10 list on the homepage)
  // This feature should be thought of more as an option to include a collection of products on a blogentry or other customer-generated piece of content
  const { data } = useQuery(productsByCategoryOrSkuDocument, {
    variables: { categoryId: btoa(categoryId ?? ''), skus },
    // to improve performance we skip this call if we already have items available to us
    skip: Array.isArray(items) && items.length > 0,
  })

  // merge fetched items from our useQuery to a single array
  const categoryItems = data?.categories?.items?.[0]?.products?.items?.filter(nonNullable) ?? []
  const productItems = data?.products?.items?.filter(nonNullable) ?? []
  const itemsFromQuery = [...productItems, ...categoryItems]

  // if we have items on pageload use those
  if (items && items?.length) {
    productListItems.items = [...items.filter(nonNullable)]
  }
  // if we dont, use the items from our query
  else if (itemsFromQuery && itemsFromQuery.length) {
    productListItems.items = [...itemsFromQuery.filter(nonNullable)]
  }

  return (
    <ContainerWithHeader
      title={title}
      rightArea={pageLinks.map((pageLink) => (
        <Link color='inherit' href={pageLink.url} key={pageLink.url} underline='always'>
          {pageLink.title}
        </Link>
      ))}
    >
      <ProductListItems title={title} {...productListItems} size='small' titleComponent='h3' />
    </ContainerWithHeader>
  )
}
