import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { Quote, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Typography } from '@mui/material'
import { RowQuoteFragment } from './RowQuote.gql'

type RowQuoteProps = RowQuoteFragment

export function RowQuote(props: RowQuoteProps) {
  const { authorName, authorAsset, authorInfo, quote } = props

  return (
    <Quote sx={(theme) => ({ my: theme.spacings.xxl })}>
      <Box sx={(theme) => ({ display: 'flex', flexDirection: 'column', gap: theme.spacings.md })}>
        <RichText
          {...quote}
          sxRenderer={{
            paragraph: (theme) => ({
              typography: 'h4',
              fontStyle: 'italic',
              maxWidth: '60%',
              fontWeight: 500,
              fontSize: `${responsiveVal(21, 36)} !important`,
              textAlign: 'center',
              margin: '0 auto',
              letterSpacing: '-0.7px',
              [theme.breakpoints.up('lg')]: {
                maxWidth: '80%',
              },
            }),
          }}
        />

        {(authorAsset || authorName || authorInfo) && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacings.xs,
              alignItems: 'center',
              justifyContent: 'center',
              [theme.breakpoints.up('lg')]: {
                margin: '0 auto',
                maxWidth: '80%',
              },
            })}
          >
            {authorAsset && (
              <Image
                src={authorAsset.url}
                alt={authorAsset.alt ?? undefined}
                sizes={responsiveVal(50, 70)}
                width={70}
                height={70}
                sx={{ objectFit: 'cover', borderRadius: '100%' }}
                pictureProps={{
                  sx: {
                    display: 'flex',
                    width: responsiveVal(50, 70),
                    height: responsiveVal(50, 70),
                  },
                }}
              />
            )}
            {(authorName || authorInfo) && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {authorName && (
                  <Typography variant='h4' component='span' sx={{ color: 'secondary.dark' }}>
                    {authorName}
                  </Typography>
                )}
                {authorInfo && (
                  <RichText
                    {...authorInfo}
                    sxRenderer={{ link: { color: 'secondary.dark', textDecoration: 'underline' } }}
                  />
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Quote>
  )
}
