import { Asset, RichText } from '@graphcommerce/graphcms-ui'
import { breakpointVal, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Typography, useTheme } from '@mui/material'
import { RowContentBannerCtaFragment } from '../RowContentBannerCta.gql'

export function TwoColumn(props: RowContentBannerCtaFragment) {
  const { bannerAsset, copy, title } = props
  const mimeType = bannerAsset?.mimeType
  const theme = useTheme()

  if (!mimeType) return null

  return (
    <Box
      sx={{
        mb: theme.spacings.md,
        display: 'grid',
        border: `1px solid ${theme.palette.divider}`,
        justifyItems: 'center',
        columnGap: `${theme.spacings.lg}`,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          background: 'none',
          gridTemplateColumns: '1fr auto',
          columnGap: `${theme.spacings.lg}`,
        },
        ...breakpointVal(
          'borderRadius',
          theme.shape.borderRadius * 2,
          theme.shape.borderRadius * 3,
          theme.breakpoints.values,
        ),
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          padding: `${theme.spacings.md} 0`,
          color: theme.palette.text.primary,
          maxWidth: '80%',
          display: 'grid',
          alignContent: 'center',
          [theme.breakpoints.up('md')]: {
            maxWidth: '70%',
          },
          '& > *': {
            maxWidth: 'max-content',
          },
        }}
      >
        {title && <Typography variant='h5'>{title}</Typography>}
        {copy?.raw && (
          <RichText
            {...copy}
            sxRenderer={{
              'bulleted-list': {
                pl: theme.spacings.xs,
                mb: 0,
                '& li': {
                  typography: 'body2',
                  listStyleType: 'disc',
                  pl: 1,
                  py: 0.2,
                },
              },
            }}
            withMargin
          />
        )}
      </Box>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          [theme.breakpoints.up('md')]: {
            height: '100%',
            width: responsiveVal(200, 550),
          },
          '& img': {
            height: '100% !important',
            width: '100% !important',
            objectFit: `cover`,
          },
        }}
      >
        {bannerAsset && (
          <Asset sizes={responsiveVal(350, 550)} asset={bannerAsset} quality={84} layout='fill' />
        )}
      </Box>
    </Box>
  )
}
