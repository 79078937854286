import { RowContentBannerCtaFragment } from './RowContentBannerCta.gql'
import { FullWidth, Inset, TwoColumn } from './variant'

type VariantRenderer = Record<
  NonNullable<RowContentBannerCtaFragment['bannerVariant']>,
  React.VFC<RowContentBannerCtaFragment>
>

type RowContentBannerCtaProps = RowContentBannerCtaFragment & {
  renderer?: Partial<VariantRenderer>
}

const defaultRenderer: Partial<VariantRenderer> = {
  FullWidth,
  Inset,
  TwoColumn,
}

export function RowContentBannerCta(props: RowContentBannerCtaProps) {
  const { renderer, bannerVariant, ...RowContentBannerCtaProps } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as VariantRenderer

  if (!bannerVariant) return null

  const RenderType =
    mergedRenderer?.[bannerVariant] ??
    (() => {
      if (process.env.NODE_ENV !== 'production') return <>renderer for {bannerVariant} not found</>
      return null
    })

  return <RenderType {...RowContentBannerCtaProps} />
}
