import { RichText } from '@graphcommerce/graphcms-ui'
import { SxRenderer } from '@graphcommerce/graphcms-ui/components/RichText/types'
import { ColumnTwo } from '@graphcommerce/next-ui'
import { SxProps, Theme } from '@mui/material'
import { RowColumnTwoFragment } from './RowColumnTwo.gql'

export function RowColumnTwo(
  props: RowColumnTwoFragment & { sx?: SxProps<Theme> } & { sxRenderer?: SxRenderer },
) {
  const { colOne, colTwo, sx, sxRenderer } = props

  return (
    <ColumnTwo
      colOneContent={<RichText {...colOne} sxRenderer={sxRenderer} />}
      colTwoContent={<RichText {...colTwo} sxRenderer={sxRenderer} />}
      sx={[(theme) => ({ marginBottom: theme.spacings.xxl }), ...(Array.isArray(sx) ? sx : [sx])]}
    />
  )
}
