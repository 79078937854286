import { RichText } from '@graphcommerce/graphcms-ui'
import { HeroBanner } from '@graphcommerce/next-ui'
import { Button } from '@mui/material'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'

export function RowHeroBanner(props: RowHeroBannerFragment) {
  const { copy, heroAsset, pageLinks } = props

  return (
    <HeroBanner
      pageLinks={pageLinks.map(({ url, title }) => (
        <Button
          key={url}
          href={url}
          variant='pill'
          color='inherit'
          size='large'
          sx={(theme) => ({
            ...theme.typography.h5,
          })}
        >
          {title}
        </Button>
      ))}
      videoSrc={heroAsset.url}
      alt={heroAsset.alt ?? undefined}
      mimeType={heroAsset.mimeType}
      sx={(theme) => ({
        px: 0,
        [theme.breakpoints.up('sm')]: {
          px: 0,
        },
        '& .HeroBanner-wrapper': {
          borderRadius: 0,
        },
        '& .HeroBanner-copy': {
          minHeight: { xs: 'min(45vh, 350px)', sm: 'min(70vh, 680px)' },
          justifyItems: 'start',
          alignContent: 'end',
          textAlign: 'left',
          color: 'primary.contrastText',
          [theme.breakpoints.up('md')]: {
            padding: theme.spacings.xl,
            width: '50%',
          },
        },
        '& video': {
          transform: 'none !important',
        },
      })}
    >
      <RichText
        {...copy}
        sxRenderer={{
          paragraph: {
            typography: 'overline',
          },
          'heading-one': (theme) => ({
            mt: 1,
            mb: theme.spacings.sm,
            '& strong': {
              WebkitTextFillColor: 'transparent',
              WebkitTextStroke: `1.2px ${theme.palette.primary.contrastText}`,
            },
          }),
        }}
      />
    </HeroBanner>
  )
}
