import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { ColumnTwo, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Typography } from '@mui/material'
import Script from 'next/script'
import { RowFormsAppFragment } from '../RowFormsApp.gql'

export function TwoColumn(props: RowFormsAppFragment & { init?: any }) {
  const { init, formId, formTitle, formText, asset } = props

  return (
    <ColumnTwo
      sx={(theme) => ({
        my: 0,
        mb: theme.spacings.xxl,
        backgroundColor: 'background.grey',
        alignItems: 'center',
        gap: theme.spacings.lg,
        py: theme.spacings.lg,
        paddingLeft: theme.spacings.md,
        paddingRight: theme.spacings.md,
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacings.lg,
          paddingRight: theme.spacings.lg,
        },
      })}
      maxWidth='lg'
      colOneContent={
        asset && (
          <Image
            src={asset.url}
            alt={asset.alt ?? undefined}
            width={asset.width ?? 0}
            height={asset.height ?? 0}
            sizes={responsiveVal(280, 500)}
            layout='intrinsic'
            sx={{
              maxWidth: () => {
                const width = asset.width ?? 0

                return {
                  xs: width * 0.65,
                  sm: width * 0.8,
                  md: width * 0.9,
                  lg: width,
                }
              },
            }}
            pictureProps={{
              sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          />
        )
      }
      colTwoContent={
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacings.sm,
          })}
        >
          {formTitle && <Typography variant='h2'>{formTitle}</Typography>}
          {formText && <RichText {...formText} />}
          <Box {...({ formsappid: formId } as any)} sx={{ display: 'flex' }} />
          <Script
            src='https://my.forms.app/static/iframe.js'
            strategy='afterInteractive'
            onLoad={init}
          />
        </Box>
      }
    />
  )
}
