import { Asset } from '@graphcommerce/graphcms-ui'
import { VariantLogoSwiper, responsiveVal } from '@graphcommerce/next-ui'
import { Link } from '@mui/material'
import { useRouter } from 'next/router'
import { RowLinksFragment } from '../RowLinks.gql'

export function LogoSwiper(props: RowLinksFragment) {
  const { title, pageLinks } = props
  const router = useRouter()

  return (
    <VariantLogoSwiper
      maxWidth={router.asPath === '/' ? undefined : false}
      title={title}
      sx={(theme) => ({
        mb: theme.spacings.xxl,
        scrollPaddingInline: 'auto',
        '& .RowLinks-title': {
          ...theme.typography.h2Overline,
          mb: theme.spacings.sm,
        },
        '& .Scroller-root': {
          gap: { xs: responsiveVal(60, 120) },
          scrollPaddingInline: { xs: responsiveVal(60, 120) },
          scrollPadding: { xs: responsiveVal(60, 120) },
        },
      })}
    >
      {pageLinks.map((pageLink) => (
        <Link
          href={pageLink.url}
          key={pageLink.id}
          color='inherit'
          underline='hover'
          sx={{
            '& img': {
              display: 'block',
              filter: 'grayscale(100%) brightness(70%) contrast(150%)',
              opacity: 0.8,
            },
          }}
        >
          {pageLink?.asset && (
            <Asset
              asset={pageLink.asset}
              sx={{
                width: () => {
                  const widthBase = 60
                  const scaleFactor = 0.425
                  const originalWidth = pageLink?.asset?.width || 0
                  const originalHeight = pageLink?.asset?.height || 0
                  const imageRatio = originalWidth / originalHeight
                  const width = imageRatio ** scaleFactor * widthBase
                  return { xs: width * 0.75, sm: width * 0.8, md: width * 0.9, lg: width }
                },
                filter: (theme) => (theme.palette.mode === 'dark' ? 'invert(100%)' : 'none'),
              }}
              sizes={{ 0: '120px', 960: '240px' }}
            />
          )}
        </Link>
      ))}
    </VariantLogoSwiper>
  )
}
