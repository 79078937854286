import { useQuery } from '@graphcommerce/graphql'
import {
  AddProductsToCartForm,
  ProductListItemFragment,
  ProductListItemsFragment,
} from '@graphcommerce/magento-product'
import { RenderType, nonNullable } from '@graphcommerce/next-ui'
import { SxProps, Theme, Typography, Breakpoint, useTheme } from '@mui/material'
import { productListRenderer } from '../../../ProductListItems'
import { productsByCategoryOrSkuDocument } from '../ProductsByCategoryOrSku.gql'
import { RowProductFragment } from '../RowProduct.gql'
import { ProductSlider } from '../components/ProductSlider'

type SwipeableProps = Omit<RowProductFragment, 'pageLinks'> &
  ProductListItemsFragment & {
    containerMaxWidth?: Breakpoint | false
    sx?: SxProps<Theme>
  }

export function Swipeable(props: SwipeableProps) {
  const { title, items, skUs, categoryId, containerMaxWidth = false, sx = [] } = props
  const skus = skUs?.replaceAll(' ', '').split(',')
  const theme = useTheme()

  // We've modified Swipeable.tsx so its able to fetch a bunch of products based on a set of SKUs or a category ID from Hygraphs' RowProduct
  // This works just fine, but will cause a client-side api call and thus fetch the content AFTER pageload (possibly causing some CLS)
  // So if possible, always pass the `items` attribute from getStaticProps whenever you're using <RowProduct/> (For example showing a top-10 list on the homepage)
  // This feature should be thought of more as an option to include a collection of products on a blogentry or other customer-generated piece of content
  const { data } = useQuery(productsByCategoryOrSkuDocument, {
    variables: { categoryId: btoa(categoryId ?? ''), skus },
    // to improve performance we skip this call if we already have items available to us
    skip: Array.isArray(items) && items.length > 0,
  })

  // merge fetched items from our useQuery to a single array
  const categoryItems = data?.categories?.items?.[0]?.products?.items?.filter(nonNullable) ?? []
  const productItems = data?.products?.items?.filter(nonNullable) ?? []
  const itemsFromQuery = [...productItems, ...categoryItems]

  let newItems: Array<ProductListItemFragment> = []

  // if we have items on pageload use those
  if (items && items?.length) {
    newItems = [...items.filter(nonNullable)]
  }
  // if we dont, use the items from our query
  else if (itemsFromQuery && itemsFromQuery.length) {
    newItems = [...itemsFromQuery.filter(nonNullable)]
  }

  if (!newItems || newItems.length === 0) return null

  return (
    <AddProductsToCartForm>
      <ProductSlider
        containerMaxWidth={containerMaxWidth}
        sidebar={
          <Typography
            variant='subtitle1'
            component='h2'
            typography='h2Overline'
            sx={{
              marginBottom: theme.spacings.sm,
            }}
          >
            {title}
          </Typography>
        }
        sx={[
          {
            marginBottom: `${theme.spacings.xxl} !important`,
            '& .ProductListItem-imageContainer > picture': { overflow: 'hidden' },
            '& .ProductListItem-imageContainer .ProductListItem-image': {
              transform: {
                xs: `translateX(calc(${theme.spacings.lg} * -1))`,
                md: `translateX(calc(${theme.spacings.md} * -1))`,
              },
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {newItems?.map((item) =>
          item ? (
            <RenderType
              key={item.uid ?? ''}
              renderer={productListRenderer}
              titleComponent='h3'
              sizes={{
                [theme.breakpoints.values.xs]: '190px',
                [theme.breakpoints.values.sm]: '220px',
                [theme.breakpoints.values.md]: '240px',
                [theme.breakpoints.values.lg]: '270px',
                [theme.breakpoints.values.xl]: '300px',
              }}
              {...item}
            />
          ) : null,
        )}
      </ProductSlider>
    </AddProductsToCartForm>
  )
}
