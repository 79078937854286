import { RichText } from '@graphcommerce/graphcms-ui'
import { ColumnOne } from '@graphcommerce/next-ui'
import { Container, Typography } from '@mui/material'
import Script from 'next/script'
import { RowFormsAppFragment } from '../RowFormsApp.gql'

export function FullWidth(props: RowFormsAppFragment & { init?: any }) {
  const { init, formId, formText, formTitle } = props

  return (
    <>
      {formTitle && <Typography variant='h2'>{formTitle}</Typography>}
      {formText && (
        <ColumnOne>
          <RichText {...formText} />
        </ColumnOne>
      )}
      <Container sx={(theme) => ({ mb: theme.spacings.xxl })}>
        <div {...({ formsappid: formId } as any)} />
        <Script
          src='https://my.forms.app/static/iframe.js'
          strategy='afterInteractive'
          onLoad={init}
        />
      </Container>
    </>
  )
}
