import { Asset, RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { responsiveVal, VariantImageLabelSwiper } from '@graphcommerce/next-ui'
import { Box, ButtonBase, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'

export function SwipeablePageLinks(props: RowLinksFragment & { sx?: SxProps<Theme> }) {
  const { pageLinks, sx } = props
  const theme = useTheme()

  return (
    <Box
      sx={{
        mb: theme.spacings.xxl,
        position: 'relative',
        background: theme.palette.background.grey,
      }}
    >
      <Image
        layout='fill'
        src='/images/bg_bicycle.png'
        alt='De fietslease maatschappij voor particulier en zakelijk leasen'
        sizes={responsiveVal(375, 597)}
        sx={{
          position: 'absolute',
          objectFit: 'contain',
          bottom: 0,
          maxWidth: {
            xs: '100%',
            sm: '597px',
          },
          height: 'auto',
        }}
      />
      <VariantImageLabelSwiper
        showButtons='auto'
        maxWidth={false}
        title=''
        copy={
          <Box>
            <Typography variant='h2'>
              <Box component='span' sx={{ color: 'primary.main' }}>
                Dé fietslease maatschappij
              </Box>{' '}
              voor particulier en zakelijk leasen
            </Typography>
          </Box>
        }
        sx={[
          () => ({
            py: theme.spacings.xxl,
            px: { xs: theme.spacings.lg, md: theme.spacings.xl },
            mb: 0,

            [theme.breakpoints.up('lg')]: {
              display: 'grid',
              gridAutoFlow: 'column',
              gap: theme.spacings.lg,
              paddingRight: 0,
            },
            '& .RowLinks-title': {
              display: 'none',
            },
            '& .RowLinks-copy': {
              marginTop: 0,
              [theme.breakpoints.down('lg')]: {
                maxWidth: '800px',
              },
              [theme.breakpoints.up('lg')]: {
                maxWidth: '400px',
              },
            },
            '& .RowLinks-scrollerWrapper': {
              overflow: 'hidden',
            },
            '& .Scroller-root': {
              columnGap: theme.spacings.xl,
            },
            '& .Scroller-root > .MuiButtonBase-root': {
              width: responsiveVal(260, 775),
              maxWidth: responsiveVal(260, 775),

              '& img, & video': {
                filter: 'none',
                willChange: 'unset',
                width: responsiveVal(260, 775),
                maxWidth: responsiveVal(260, 775),
                aspectRatio: '3 / 2',
                height: '100%',
                objectFit: 'cover',
              },
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {pageLinks.map((pageLink) => (
          <ButtonBase
            href={pageLink.url}
            key={pageLink.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: theme.spacings.md,
              '& img': { display: 'block' },
            }}
          >
            {pageLink?.asset && (
              <Asset asset={pageLink.asset} sizes={responsiveVal(260, 775)} quality={90} />
            )}
            <Box
              sx={{
                maxWidth: {
                  xl: '62%',
                  lg: '82%',
                },
              }}
            >
              <Typography variant='h3' sx={{ marginBottom: theme.spacings.sm }}>
                {pageLink.title}
              </Typography>
              <RichText raw={pageLink.description?.raw} />
            </Box>
          </ButtonBase>
        ))}
      </VariantImageLabelSwiper>
    </Box>
  )
}
