import { Row } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'

export function RowHorizontalRule() {
  return (
    <Row maxWidth={false} disableGutters>
      <Box
        component='hr'
        sx={{
          borderWidth: '1px 0 0',
          borderColor: 'divider',
          borderStyle: 'solid',
          margin: 0,
        }}
      />
    </Row>
  )
}
