import { SidebarSlider, SidebarSliderProps, responsiveVal } from '@graphcommerce/next-ui'
import { Container, Breakpoint, SxProps, Theme } from '@mui/material'

export function ProductSlider(
  props: Omit<SidebarSliderProps, 'sx'> & {
    containerMaxWidth?: Breakpoint | false
    sx?: SxProps<Theme>
  },
) {
  const { containerMaxWidth = 'xl', sx = [] } = props

  return (
    <Container maxWidth={containerMaxWidth}>
      <SidebarSlider
        {...props}
        buttonSize='responsive'
        sx={[
          (theme) => ({
            mb: `${theme.spacings.xl} !important`,

            '& .SidebarSlider-grid': {
              gridTemplateColumns: '100%',
            },
            '& .SidebarSlider-sidebar': {
              padding: '0',
              '& > :nth-of-type(2)': {
                display: 'none',
              },
            },
            '& .SidebarSlider-scroller': {
              gridAutoColumns: responsiveVal(180, 360),
              gap: responsiveVal(15, 80),
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
    </Container>
  )
}
